<template>
  <v-card id="promotion-list">
    <v-card-text class="d-flex align-center flex-wrap pb-0">
      <div class="d-flex align-center pb-5">
        <v-btn
          @click="createDialogShow()"
          color="primary"
          class="me-3">
          <v-icon
            size="18"
            class="me-1"
          >
            {{ icons.mdiPlus }}
          </v-icon>
          <span>Добавить</span>
        </v-btn>
        <v-spacer></v-spacer>
        <v-text-field
          v-model="filters.searchQuery"
          single-line
          dense
          outlined
          hide-details
          placeholder="Поиск..."
          class="promotion-list-search me-3"
        ></v-text-field>
      </div>
    </v-card-text>
    <v-progress-circular
      :size="70"
      :width="7"
      v-if="loading"
      color="purple"
      indeterminate
    ></v-progress-circular>
    <v-row>
      <v-col sm="6"
             cols="12" v-for="item in dishes" :key="item.id">
        <dish-card :dish="item" @dish-edit-clicked="updateDialogShow"></dish-card>
      </v-col>
    </v-row>
    <v-pagination
      v-model="filters.pageNumber"
      :length="filters.pageCount"
      circle
    ></v-pagination>
    <custom-dish-form :cancellation="createDialogCancel" :menu-version="menuVersion"
                      label="Создание блюда" :menuCategory="menuCategory"
                      :save="addDish" :show="createDialog"></custom-dish-form>
    <custom-dish-form :cancellation="updateDialogCancel" :menu-version="menuVersion"
                      label="Редактирование блюда" :menuCategory="menuCategory"
                      :save="updateDish" :show="updateDialog"></custom-dish-form>
  </v-card>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import {
  mdiTrendingUp,
  mdiPlus,
  mdiDeleteOutline,
  mdiDotsVertical,
  mdiEyeOutline,
  mdiPencilOutline,
} from '@mdi/js'
import DishCard from './dish-card.vue'
import CustomDishForm from '@/views/menu-version/custom-dish-form'

export default {
  components: { DishCard, CustomDishForm },
  props: ['menuCategory', 'menuVersion'],
  name: 'DishList',
  data: () => ({
    createDialog: false,
    updateDialog: false,
    icons: {
      mdiTrendingUp,
      mdiPlus,
      mdiPencilOutline,
      mdiDeleteOutline,
      mdiDotsVertical,
      mdiEyeOutline,
    },
  }),
  computed: {
    ...mapState('dish', {
      dishes: 'list',
      totalCount: 'totalCount',
      loading: 'loading',
      filters: 'filters',
    }),
  },
  async created() {
    await this.fetchDishes()
  },
  watch: {
    menuCategory: function(newValue) {
      this.filters.pageNumber = 1
      this.filters.menuCategoryId = newValue
    },
  },
  methods: {
    ...mapActions('dish', {
      fetchDishes: 'fetchList',
    }),
    ...mapActions('dish/customForm', ['create', 'resetForm', 'fetchById', 'update']),
    createDialogShow() {
      this.createDialog = true
    },
    createDialogCancel() {
      this.createDialog = false
    },
    updateDialogCancel() {
      this.updateDialog = false
    },
    async updateDish() {
      await this.update()
      this.updateDialog = false
    },
    async addDish() {
      await this.create()
      this.createDialog = false
    },
    updateDialogShow(id) {
      this.fetchById(id)
      this.updateDialog = true
    },
  },
}
</script>

<style lang="scss" scoped>
#promotion-list {
  .promotion-list-search {
    max-width: 10.625rem;
  }
}
</style>
