<template>
  <v-card>
    <v-row>
      <v-col
        cols="12"
        sm="3"
      >
        <v-toolbar
          color="transparent"
          dark
        >
          <v-btn
            color="primary"
            class="me-3"
            @click="createDialogShow()"
          >
            <v-icon
              size="18"
              class="me-1"
            >
              {{ icons.mdiPlus }}
            </v-icon>
            <span>Добавить</span>
          </v-btn>
        </v-toolbar>
        <v-list dense>
          <v-list-item-group
            v-model="selectedItem"
            color="primary"
            @change="selectionChanged"
          >
            <v-list-item
              v-for="(item, i) in menuCategories"
              :key="i"
            >
              <v-list-item-icon>
                <v-icon v-if="item.isHidden" @click="unHide(item.id)"> {{ icons.mdiEyeOutline }}
                </v-icon>
                <v-icon v-if="!item.isHidden" @click="hide(item.id)"> {{ icons.mdiEyeOffOutline }}
                </v-icon>
                <v-icon @click="updateDialogShow(item.id)"> {{ icons.mdiPencil }}</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title :style="{'text-color': item.isHidden ? 'gray' : 'black'}"
                                   v-text="item.name">
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-col>
      <v-divider :vertical="$vuetify.breakpoint.name === 'xs' ? false : true"></v-divider>
      <v-col
        cols="12"
        sm="9"
      >
        <dish-list :menu-version="menuVersionId" :menu-category="menuCategory"></dish-list>
      </v-col>
    </v-row>
    <menu-category-form :label="'Редактирование категории меню'" :cancellation="updateDialogCancel"
                        :show="updateDialog" :menu-version="params"
                        :save="updateMenuCategory"></menu-category-form>
    <menu-category-form :label="'Создание категории меню'" :cancellation="createDialogCancel"
                        :show="createDialog"
                        :save="createMenuCategory" :menu-version="params"></menu-category-form>
  </v-card>
</template>

<script>

import { mapActions, mapState } from 'vuex'
import DishList from './dish-list.vue'
import { mapFormHandlers } from '@/helpers/forms'
import {
  mdiEyeOutline,
  mdiPencil,
  mdiEyeOffOutline,
} from '@mdi/js'
import MenuCategoryForm from '@/views/menu-version/menu-category-form'

export default {
  name: 'MenuCategoryList',
  components: { MenuCategoryForm, DishList },
  data: () => ({
    createDialog: false,
    updateDialog: false,
    selectedItem: 0,
    menuCategory: 0,
    menuVersionId: 0,
    icons: {
      mdiPencil,
      mdiEyeOutline,
      mdiEyeOffOutline,
    },
  }),
  computed: {
    ...mapState('menuCategory', {
      menuCategories: 'list',
    }),
    ...mapFormHandlers('menuCategory', ['params']),
  },
  methods: {
    ...mapActions('menuCategory', {
      fetchMenuCategories: 'fetchList',
      hideMenuCategory: 'hide',
      unhideMenuCategory: 'unHide',
    }),
    ...mapActions('menuCategory/form', ['create', 'resetForm', 'fetchById', 'update']),
    selectionChanged() {
      this.menuCategory = this.menuCategories[this.selectedItem].id
    },
    async unHide(id) {
      await this.unhideMenuCategory(id)
    },
    async hide(id) {
      await this.hideMenuCategory(id)
    },
    createDialogShow() {
      this.createDialog = true
    },
    createDialogCancel() {
      this.createDialog = false
    },
    updateDialogCancel() {
      this.updateDialog = false
    },
    updateDialogShow(id) {
      this.fetchById(id)
      this.updateDialog = true
    },
    async createMenuCategory() {
      await this.create()
      this.createDialog = false
    },
    async updateMenuCategory() {
      await this.update()
      this.updateDialog = false
    },
  },
  async created() {
    document.title = 'Меню'
    const { menuVersionId } = this.$route.params
    this.menuVersionId = menuVersionId
    this.params = menuVersionId
    await this.fetchMenuCategories()
  },
}
</script>
