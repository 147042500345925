<template>
  <v-form class="multi-col-validation mt-6" ref="form">
    <v-row>
      <v-col
        md="12"
        cols="24"
      >
        <v-tabs
          v-model="tab"
          show-arrows
        >
          <v-tab v-for="(it, index) in item.translations" :key="index">
            <span>{{ it.language }}</span>
          </v-tab>
        </v-tabs>
        <v-spacer></v-spacer>
        <v-tabs-items v-model="tab">
          <v-tab-item v-for="(it, index) in item.translations" :key="index">
            <v-col
              cols="24"
              md="12"
            >
              <v-text-field
                v-model="it.partName"
                label="Наименование"
                dense
                outlined
                :rules="[rules.required]"
              ></v-text-field>
            </v-col>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
      <v-col
        md="4"
        cols="8"
      >
        <v-autocomplete
          v-model="item.dishId"
          :items="dishes"
          :menu-props="{ maxHeight: '400' }"
          label="Блюдо"
          dense
          outlined
          persistent-hint
          hide-details
        ></v-autocomplete>
      </v-col>
      <v-col
        md="4"
        cols="8"
      >
        <v-checkbox
          v-model="item.isDefault"
          label="По умолчанию"
          dense
          outlined
        ></v-checkbox>
      </v-col>
    </v-row>
  </v-form>
</template>

<script>

import { rules } from '@/helpers/validation'

export default {
  name: 'CustomDishItem',
  props: ['dishes', 'item'],
  data: () => ({
    tab: null,
    rules,
  }),
}
</script>
